import React, { Fragment } from 'react'
import ListReels from '@components/reel/list-reel'
import HomeSubLayout from './home-sub-layout'
import { Container } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useReel } from '@lib/context/reel-context'
import RouterUrl from '@constants/router.constant'

const ReelModal = dynamic(() => import('@components/modals/reel'), {
  ssr: false
})

const Reel = () => {
  const { reels } = useReel()

  return (
    <Fragment>
      {reels != null && reels.length > 0 && (
        <HomeSubLayout
          title="WATCH & SHOP"
          linkTextUnderline={{
            text: 'Shop All',
            color: 'black',
            href: RouterUrl.SHOP.ROOT
          }}
        >
          <Container px={{ base: 4, lg: 5, xl: 8, '2xl': 0 }}>
            <ListReels />
          </Container>
        </HomeSubLayout>
      )}
      <ReelModal />
    </Fragment>
  )
}

export default Reel
