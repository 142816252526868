import React from 'react'

interface HeadingTagProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const HeadingTag: React.FC<HeadingTagProps> = ({
  children,
  style,
  ...props
}) => {
  return (
    <h1
      className="lg:text-[42px] text-[32px]"
      style={{
        width: '100%',
        fontFamily: 'ITC',
        fontWeight: 500,
        ...style
      }}
      {...props}
    >
      {children}
    </h1>
  )
}

export default HeadingTag
