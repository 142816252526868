import React from 'react'
import { Box, BoxProps, Container, ContainerProps } from '@chakra-ui/react'
import { useResponsive } from '@common/hooks'

interface PropsType {
  children: React.ReactNode
  banner?: string | null
  containerProps?: ContainerProps
}

const BoxClippathImage = ({
  children,
  banner,
  containerProps,
  ...rest
}: PropsType & BoxProps) => {
  const { lgMax } = useResponsive()

  return (
    <Container px={{ base: 4, lg: 5, xl: 0, '2xl': 0 }} {...containerProps}>
      <Box
        bg="#e9e9df"
        bgImage={banner ? `url(${banner})` : ''}
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPosition="bottom right"
        {...rest}
      >
        {children}
      </Box>
    </Container>
  )
}

export default BoxClippathImage
