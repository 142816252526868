import axios, { AxiosResponse } from 'axios'
import { BACKEND_URL_STORE } from '@constants/server.constant'
import { ProductFeature } from '@common/interface/product-feature.interface'

export const listProductFeatures = async (query?: any) => {
  try {
    const { data } = await axios.get<{
      count: number
      product_features: ProductFeature[]
    }>(`${BACKEND_URL_STORE}/product-feature`, {
      params: query
    })
    return data
  } catch (error) {
    return { count: 0, product_features: [] }
  }
}

export const getProductFeatureById = (
  id: string
): Promise<AxiosResponse<any, any> | undefined> =>
  axios.get(`${BACKEND_URL_STORE}/product-feature/${id}`)
