import React from 'react'

import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { MdKeyboardArrowLeft } from '@icons/md-keyboard-arrow-left'
import { MdKeyboardArrowRight } from '@icons/md-keyboard-arrow-right'

interface CategoriesSlideNavigationProps extends Omit<IconButtonProps, 'type'> {
  type: 'left' | 'right'
  onClick?: () => void
  hide?: boolean
  bg?: string
  color?: string
  hIcon?: number
  wIcon?: number
}

const CategoriesSlideNavigation: React.FC<CategoriesSlideNavigationProps> = ({
  type,
  onClick,
  hide = false,
  bg,
  color,
  hIcon,
  wIcon,
  ...rest
}) => {
  if (hide) return null

  return (
    <IconButton
      zIndex={1}
      onClick={onClick}
      _hover={{ bg: bg ?? 'white' }}
      bg={bg ?? 'white'}
      px={0}
      {...rest}
      icon={
        type === 'left' ? (
          <MdKeyboardArrowLeft
            width={wIcon}
            height={hIcon}
            color={color ?? 'black'}
          />
        ) : (
          <MdKeyboardArrowRight
            width={wIcon}
            height={hIcon}
            color={color ?? 'black'}
          />
        )
      }
    />
  )
}

export default CategoriesSlideNavigation
