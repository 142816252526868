import type { MainBanner } from '../../types/image'
import React from 'react'
import { Box } from '@chakra-ui/react'
import BannerSlide from '@components/banner-slide'

interface HeaderSlideBannerProps {
  banners: MainBanner[]
}

const HeaderSlideBanner: React.FC<HeaderSlideBannerProps> = ({ banners }) => {
  return (
    <Box w="100%">
      <BannerSlide banners={banners} />
    </Box>
  )
}

export default HeaderSlideBanner
