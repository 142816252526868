import React, { Fragment } from 'react'
import { Box, Container, Flex, Text } from '@chakra-ui/react'
import LinkTextUnderline, {
  LinkTextUnderlinePropsType
} from '@components/link-text-underline'
import { useResponsive } from '@common/hooks'
import HeadingTag from '@components/heading-tag'

interface PropsType {
  title: string
  linkTextUnderline: LinkTextUnderlinePropsType
  children: React.ReactNode
  display?: string
}

const HomeSubLayout = ({
  title,
  linkTextUnderline,
  children,
  display
}: PropsType) => {
  const { xlMax, xlMin } = useResponsive()

  return (
    <Box
      py={{
        md: 24,
        sm: 20,
        base: 16
      }}
    >
      <Container display={display ?? 'block'} position="relative">
        <HeadingTag
          style={{ textAlign: 'center', marginBottom: xlMax ? '48px' : '64px' }}
        >
          {title}
        </HeadingTag>
      </Container>
      <Flex
        w={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        {children}
      </Flex>
      <Container>
        <Box
          textAlign={'center'}
          mt={{
            lg: '60px',
            base: '40px'
          }}
        >
          <LinkTextUnderline
            text={linkTextUnderline.text}
            color={linkTextUnderline.color}
            href={linkTextUnderline.href}
            textStyle="smallTitle"
          />
        </Box>
      </Container>
    </Box>
  )
}

export default HomeSubLayout
