import React, { useEffect } from 'react'
import Image from 'next/image'
import { Box, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import headerFooterImage from '@images/header-footer.png'
import LinkTextUnderline from '@components/link-text-underline'
import { useResponsive } from '@common/hooks'
import RouterUrl from '@constants/router.constant'
import BrandOfJml from '@components/brand-of-jml'
import AOS from 'aos'

const HomeFooter = () => {
  const { mdMax } = useResponsive()
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <Box position="relative">
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          colSpan={{
            lg: 4,
            base: 12
          }}
          alignItems="center"
          display="flex"
          bg="bgBold"
          py={16}
          data-aos="fade-right"
        >
          <Grid templateColumns="repeat(12, 1fr)" w="100%">
            <GridItem
              colStart={{
                lg: 2,
                base: 1
              }}
              colEnd={{
                lg: 12,
                base: 13
              }}
              px={4}
            >
              <Stack gap={mdMax ? 8 : 10}>
                <Box>
                  <Text textStyle="sectionHeader">We are all about</Text>
                  <Text textStyle="sectionHeader">Happy Habitats.</Text>
                </Box>
                <Text textStyle="sectionHeaderText">
                  We are the knowledge bearers to simplifying your life with
                  innovative home solutions. We are the happy movement, the
                  cradle of living good, inspiring everyday simplicity and
                  wellness from the moment you wake up, till you go to bed.
                </Text>
                <LinkTextUnderline
                  text={'Our Story'}
                  href={RouterUrl.OUR_STORY}
                  color={'black'}
                  underlineColor={'#DE4800'}
                  zIndex={100}
                  textStyle="smallTitle"
                ></LinkTextUnderline>
                <BrandOfJml />
              </Stack>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem
          colSpan={{
            lg: 8,
            base: 12
          }}
          position="relative"
          minH="100%"
          style={{
            aspectRatio: '1140/630'
          }}
          data-aos="fade-left"
        >
          <Image
            src={headerFooterImage}
            alt="We are all about Happy Habitats"
            width={1140}
            height={630}
            className="object-cover w-full h-full"
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default HomeFooter
