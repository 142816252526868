import type { MainBanner } from '../types/image'

import React from 'react'
import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { filterSlideBanner } from '@lib/util/map-to-slide-banner'
import Image from 'next/image'
import { useResponsive } from '@common/hooks'
import LinkTextUnderline from './link-text-underline'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

interface BannerSlideProps {
  banners: MainBanner[]
}

const BackgroundText = ({ b }: { b: MainBanner }) => {
  const { mdMax, lgMax } = useResponsive()

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      position={'absolute'}
      top={'0'}
      left={'0'}
      justifyContent={'center'}
      mt={{ base: 20, sm: 20, md: 25, lg: 30, xl: 36 }}
    >
      <Box textAlign={'center'}>
        <VStack>
          {b.logo != null && (
            <Image
              src={b.logo}
              width={250}
              height={200}
              alt={'logo banner'}
              style={{
                width: mdMax ? '150px' : lgMax ? '200px' : '250px',
                height: mdMax ? '100px' : lgMax ? '150px' : '200px'
              }}
            />
          )}
          <VStack spacing={0}>
            {b.writeup != null && (
              <Text
                fontSize={{ base: '20px', md: '35px' }}
                fontFamily={'ITC'}
                fontWeight={'500'}
                maxW={'800px'}
                color={b.writeup_color ?? 'white'}
              >
                {b.writeup}
              </Text>
            )}
            {b.sub_writeup != null && (
              <Text
                fontSize={{ base: '15px', md: '25px' }}
                fontFamily={'DM Sans Regular, sans-serif'}
                maxW={'800px'}
                color={b.writeup_color ?? 'white'}
                mt={0}
              >
                {b.sub_writeup}
              </Text>
            )}
          </VStack>
        </VStack>
        {b.link_name && (
          <Box mt={5}>
            <LinkTextUnderline
              text={b.link_name ?? 'Shop Now'}
              color={b?.link_color ?? 'white'}
              href={b.link}
            />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

const BannerSlide: React.FC<BannerSlideProps> = ({ banners }) => {
  const filteredBanners = filterSlideBanner(banners)

  const getBannerImage = (banner: MainBanner) => {
    return {
      subBanner: hasSubBanner(banner) ? banner.sub_banner_image : '',
      banner: banner.image
    }
  }

  const hasSubBanner = (
    banner: MainBanner
  ): banner is MainBanner & {
    sub_banner_image: string
  } => !!banner.sub_banner_image

  return (
    <Swiper
      spaceBetween={30}
      effect="fade"
      navigation={{
        enabled: true
      }}
      pagination={{
        clickable: true
      }}
      a11y={{
        enabled: true
      }}
      modules={[EffectFade, Navigation, Pagination, Autoplay]}
      className="mySwiper"
      autoplay={{
        delay: 6000,
        disableOnInteraction: false
      }}
      loop={false}
    >
      {filteredBanners.map((banner) => {
        return (
          <SwiperSlide key={banner.id}>
            <Link href={banner.link}>
              <Box key={banner.id} w="100%" position="relative">
                <Image
                  alt="Main banner"
                  className="w-full xsmall:hidden"
                  priority={true}
                  width={375}
                  height={500}
                  quality={100}
                  src={getBannerImage(banner).subBanner}
                />
                <Image
                  src={getBannerImage(banner).banner}
                  alt="Main banner"
                  className="w-full hidden xsmall:block"
                  priority={true}
                  width={1920}
                  height={800}
                />

                <Box
                  bg="blackOpacity"
                  position="absolute"
                  top={0}
                  left={0}
                  height="100%"
                  width="100%"
                ></Box>
                <BackgroundText b={banner} />
              </Box>
            </Link>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default BannerSlide
