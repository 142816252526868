import React, { Fragment, useEffect, useState } from 'react'
import HomeSubLayout from '../home-sub-layout'
import { ProductFeature as ProductFeatureType } from '@common/interface/product-feature.interface'
import RouterUrl from '@constants/router.constant'
import PaginationLayout from '@layouts/pagination-layout'
import { useQuery } from '@tanstack/react-query'
import { getProductFeatureById } from '@lib/util/product-feature-handler'
import ProductCardItem from '@components/product/product-card-item'
import { useFeaturedProductsQuery } from '@lib/hooks/use-layout-data'
import { ITEMS_PER_PAGE } from '@constants/product.constant'
import AOS from 'aos'

interface ProductFeatureProps {
  product_feature: ProductFeatureType
}

const ProductFeature: React.FC<ProductFeatureProps> = ({ product_feature }) => {
  const [prodIds, setProdIds] = useState<string[]>([])

  const { data, isLoading } = useQuery(
    ['product-feature', product_feature],
    async () => await getProductFeatureById(product_feature.id)
  )

  const { data: dataQuery, isLoading: fetching } = useFeaturedProductsQuery({
    id: prodIds
  })

  useEffect(() => {
    if (data != null && data.data?.products?.length > 0) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setProdIds([...data.data?.products?.map((p: any) => p.id)])
    }
  }, [data])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Fragment>
      {data != null &&
        dataQuery != null &&
        dataQuery.products?.length > 0 &&
        prodIds.length > 0 && (
          <HomeSubLayout
            title={product_feature.title}
            linkTextUnderline={{
              text: 'Shop All',
              color: 'black',
              href: RouterUrl.SHOP.ROOT
            }}
          >
            <PaginationLayout
              data={dataQuery?.products ?? []}
              isLoading={isLoading || fetching}
              itemsPerPage={ITEMS_PER_PAGE}
              skipPagination={false}
              renderItem={(item: any, index: number) => {
                return (
                  <ProductCardItem
                    key={index}
                    item={item}
                    data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
                  />
                )
              }}
            />
          </HomeSubLayout>
        )}
    </Fragment>
  )
}

export default ProductFeature
