import React, { useEffect } from 'react'
import Image from 'next/image'
import { Box, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import headerFooterImage from '@images/header-footer.png'
import LinkTextUnderline from '@components/link-text-underline'
import { useResponsive } from '@common/hooks'
import RouterUrl from '@constants/router.constant'
import BrandOfJml from '@components/brand-of-jml'
import AOS from 'aos'

const JmlHomeFooter = () => {
  const { mdMax } = useResponsive()

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box position="relative">
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          colSpan={{
            lg: 6,
            base: 12
          }}
          alignItems="center"
          display="flex"
          bg="#3cadee"
          py={16}
          data-aos="fade-right"
        >
          <Grid templateColumns="repeat(12, 1fr)" w="100%">
            <GridItem
              colStart={{
                lg: 2,
                base: 1
              }}
              colEnd={{
                lg: 12,
                base: 13
              }}
              px={4}
            >
              <Stack gap={mdMax ? 8 : 10}>
                <Box>
                  <Text textStyle="sectionHeader" color="white">
                    Home of Clever Ideas
                  </Text>
                </Box>
                <Text textStyle="sectionHeaderText" color="white">
                  We love discovering new ideas at JML and, for over three
                  decades, our passion has been seeking out clever ideas to
                  solve some of life’s little problems.
                </Text>
                <LinkTextUnderline
                  text={'Our Story'}
                  href={RouterUrl.OUR_STORY}
                  color="white"
                  underlineColor={'#e09a29'}
                  zIndex={100}
                  textStyle="smallTitle"
                />
              </Stack>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem
          colSpan={{
            lg: 6,
            base: 12
          }}
          position="relative"
          minH="100%"
          style={{
            aspectRatio: '1140/630'
          }}
          data-aos="fade-left"
        >
          <Image
            src={headerFooterImage}
            alt="Home of Clever Ideas"
            width={1140}
            height={630}
            className="object-cover w-full h-full"
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default JmlHomeFooter
