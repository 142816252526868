import React from 'react'
import ProductFeature from './product-feaure'
import { ProductFeature as IProductFeature } from '@common/interface/product-feature.interface'

interface ListProductFeatureProps {
  products: IProductFeature[]
}

function filterFeaturedProducts(featuredProducts: IProductFeature[]) {
  return featuredProducts.filter((product) => {
    if (product.start_date == null && product.end_date == null) {
      return product
    } else if (product.start_date != null) {
      if (new Date(product.start_date).getTime() <= new Date().getTime()) {
        return product
      }
      // eslint-disable-next-line no-dupe-else-if
    } else if (product.start_date != null && product.end_date != null) {
      const isStart =
        new Date(product.start_date).getTime() <= new Date().getTime()
      const isEnd = new Date(product.end_date).getTime() > new Date().getTime()
      if (isStart && !isEnd) {
        return product
      }
    }
  })
}

const FeaturedProducts: React.FC<ListProductFeatureProps> = ({ products }) => {
  const productFeaturesFilter = filterFeaturedProducts(products ?? [])

  return (
    <>
      {productFeaturesFilter.map((product, index) => {
        return (
          <ProductFeature
            key={`${product.id}-${index}`}
            product_feature={product}
          />
        )
      })}
    </>
  )
}

export default FeaturedProducts
