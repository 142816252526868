import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import BoxClippathImage from '@components/box-clippath-image'
import RouterUrl from '@constants/router.constant'
import { PriceList } from '@medusajs/medusa'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import HomeSubLayout from './home-sub-layout'
import { convertUTCDateToLocalDate } from '@lib/util/date'
import PaginationLayout from '@layouts/pagination-layout'
import ProductCardItem from '@components/product/product-card-item'
import { ITEMS_PER_PAGE } from '@constants/product.constant'
import {
  useListPriceList,
  useListProductsPriceList
} from '@common/hooks/use-price-list'

const DealsDate = ({ date, text }: { date: string; text: string }) => {
  return (
    <Stack
      w={{
        base: '70px',
        md: '75px'
      }}
      gap={3}
    >
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        h={24}
        bg={'white'}
      >
        <Text textStyle="homeDealDate" textAlign={'center'} mt={3}>
          {parseInt(date) < 10 ? `0${date}` : date}
        </Text>
      </Flex>
      <Text textAlign={'center'} textStyle={'homeDealText'}>
        {text}
      </Text>
    </Stack>
  )
}

const HomeDeals = () => {
  const [deal, setDeal] = useState<PriceList | null>(null)

  const router = useRouter()

  const { listPriceLists, isLoading } = useListPriceList()
  const { listProductsPriceList } = useListProductsPriceList('undefined', {
    limit: 20,
    offset: 0,
    price_list_id: deal?.id ? [deal?.id] : []
  })

  useEffect(() => {
    if (listPriceLists.length > 0) {
      const price_list = listPriceLists[listPriceLists.length - 1]
      if (price_list) {
        setDeal(price_list)
      }
    }
  }, [listPriceLists])

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    completed: any
  }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Flex
          gap={{
            md: 4,
            base: 2
          }}
        >
          <DealsDate date={`${days as string}`} text="Days" />
          <DealsDate date={`${hours as string}`} text="Hours" />
          <DealsDate date={`${minutes as string}`} text="Minutes" />
          <DealsDate date={`${seconds as string}`} text="Seconds" />
        </Flex>
      )
    }
  }

  return (
    <Fragment>
      {deal && listProductsPriceList.length > 0 ? (
        <Fragment>
          <HomeSubLayout
            title="Deals"
            linkTextUnderline={{
              text: 'Shop All Deals',
              color: 'black',
              href: RouterUrl.FLASH_SALE.ROOT
            }}
          >
            <BoxClippathImage
              banner={(deal as any).banner}
              px={{ base: 4, sm: 8, md: '100px' }}
              py={{ base: 10, md: '100px' }}
            >
              <Stack gap={4}>
                <Text textStyle={'homeDealTitle'}>{deal.name}</Text>
                <Text textStyle={'homeDealText'}>{deal.description}</Text>
                <Button
                  w={0}
                  h={0}
                  color={'white'}
                  bg={'red'}
                  py={5}
                  px={16}
                  borderRadius={20}
                  onClick={() => router.push(RouterUrl.FLASH_SALE.ROOT)}
                  _hover={{
                    bg: 'red'
                  }}
                >
                  Shop Now
                </Button>

                {deal &&
                  deal.ends_at &&
                  (deal as any).show_product_countdown && (
                    <Countdown
                      date={
                        Date.now() +
                        (new Date(deal.ends_at).getTime() -
                          convertUTCDateToLocalDate(new Date()).getTime())
                      }
                      renderer={renderer}
                    ></Countdown>
                  )}
              </Stack>
            </BoxClippathImage>

            <PaginationLayout
              data={listProductsPriceList ?? []}
              isLoading={isLoading}
              itemsPerPage={ITEMS_PER_PAGE}
              skipPagination={false}
              containerProps={{ mt: 20 }}
              renderItem={(item: any, index: number) => {
                return <ProductCardItem key={index} item={item} />
              }}
            />
          </HomeSubLayout>
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default HomeDeals
