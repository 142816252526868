import React, { useEffect, useState } from 'react'
import { Container, SimpleGrid } from '@chakra-ui/react'
import HomeSubLayout from './home-sub-layout'
import { useResponsive } from '@common/hooks'
import RouterUrl from '@constants/router.constant'
import { chunkArray } from '@lib/util/chunk-array'
import { ProductCategory } from '@medusajs/medusa'
import CategoriesSlideNavigation from '@components/home/shop/CategoriesSlideNavigation'
import CategoryImage from '@components/home/CategoryImage'
import AOS from 'aos'

const GridCategories = ({ categories }: { categories: any[] }) => {
  if (categories.length) {
    const mainCategory = categories[0]
    const otherCategories = categories.slice(1)

    return (
      <>
        {mainCategory && (
          <CategoryImage
            image={mainCategory.img_background || ''}
            text={mainCategory.name || ''}
            id={mainCategory.id ?? ''}
            handle={mainCategory.handle}
          />
        )}
        <SimpleGrid
          templateColumns={{
            sm: '1fr 1fr',
            xl: '6fr 6fr'
          }}
          spacing={3}
          w={'100%'}
          maxH={'100%'}
        >
          {otherCategories.map((category) => (
            <CategoryImage
              key={category.id}
              id={category.id}
              image={category.img_background || ''}
              text={category.name || ''}
              handle={category?.handle}
            />
          ))}
        </SimpleGrid>
      </>
    )
  }
  return <></>
}

interface Category extends ProductCategory {
  img_background: string
}

interface HomeShopProps {
  productCategories: ProductCategory[]
}

const HomeShop: React.FC<HomeShopProps> = ({ productCategories }) => {
  const { xlLargeMax, xlMin } = useResponsive()

  const categories = (productCategories || []) as Category[]
  const gridCategories = chunkArray(productCategories, 5)

  const [slide, setSlide] = useState<number>(0)

  const handleChangeSlide = (handle: 'next' | 'prev') => {
    if (handle === 'next') {
      if (slide + 1 <= categories.length - 1) {
        setSlide(slide + 1)
      }
      return
    }

    if (slide - 1 >= 0) {
      setSlide(slide - 1)
    }
  }

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <HomeSubLayout
      title="Shop"
      linkTextUnderline={{
        text: 'Shop All',
        color: 'black',
        href: RouterUrl.SHOP.ROOT
      }}
    >
      <Container position="relative">
        <CategoriesSlideNavigation
          type="left"
          aria-label="Previous category"
          wIcon={xlMin ? 18 : undefined}
          hIcon={xlMin ? 33 : undefined}
          onClick={() => handleChangeSlide('prev')}
          position="absolute"
          top="50%"
          left={0}
          bg={xlLargeMax ? 'blackButtonSwitch' : 'white'}
          color={xlLargeMax ? 'white' : 'black'}
          transform={
            xlLargeMax
              ? 'translateY(-50%) translateX(50%)'
              : 'translateY(-50%) translateX(-200%)'
          }
        />

        <CategoriesSlideNavigation
          type="right"
          aria-label="Next category"
          wIcon={xlMin ? 18 : undefined}
          hIcon={xlMin ? 33 : undefined}
          onClick={() => handleChangeSlide('next')}
          position="absolute"
          top="50%"
          right={0}
          bg={xlLargeMax ? 'blackButtonSwitch' : 'white'}
          color={xlLargeMax ? 'white' : 'black'}
          transform={
            xlLargeMax
              ? 'translateY(-50%) translateX(-50%)'
              : 'translateY(-50%) translateX(200%)'
          }
        />
        <SimpleGrid
          templateColumns={{
            sm: '1fr 1fr',
            xl: '8fr 6fr'
          }}
          display={{
            base: 'none',
            lg: 'grid'
          }}
          spacing={3}
          width="100%"
        >
          {productCategories.length > 0 && (
            <GridCategories categories={gridCategories[slide] || []} />
          )}
        </SimpleGrid>
        <SimpleGrid
          columns={{
            base: 1
          }}
          display={{
            base: 'grid',
            lg: 'none'
          }}
          spacing={3}
          width="100%"
          position="relative"
        >
          {categories.length > 0 && (
            <CategoryImage
              image={categories[slide]?.img_background ?? ''}
              text={categories[slide]?.name || 'text'}
              id={categories[slide]?.id ?? ''}
              handle={categories[slide]?.handle}
            />
          )}
        </SimpleGrid>
      </Container>
    </HomeSubLayout>
  )
}

export default HomeShop
