import React from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  SimpleGrid,
  Container,
  useBreakpointValue
} from '@chakra-ui/react'
import fTruck from '@images/feature/f-truck.png'
import fMoneyBillWave from '@images/feature/f-money-bill-wave.png'
import fShieldAlt from '@images/feature/f-warranty.png'
import fStore from '@images/feature/f-store.png'

import Image from 'next/image'

const features = [
  {
    icon: fTruck,
    title: 'Free Delivery',
    description: 'Above $80 (SG)'
  },
  {
    icon: fMoneyBillWave,
    title: 'Instalment Plan',
    description: 'Interest-Free (SG)'
  },
  {
    icon: fShieldAlt,
    title: 'Warranty',
    description: 'Coverage'
  },
  {
    icon: fStore,
    title: 'Experience',
    description: 'Centre'
  }
]

const FeatureSet: React.FC = () => {
  const imageSize = useBreakpointValue({ base: 80, md: 120 })

  return (
    <Container centerContent>
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={6} px={4} py={6}>
        {features.map((feature, index) => (
          <VStack
            key={index}
            spacing={2}
            alignItems="center"
            w={{ base: 150, md: 200 }}
          >
            <Box
              w="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              minH={{ base: 100, md: 140 }}
            >
              <Image
                src={feature.icon}
                alt={feature.title}
                width={imageSize}
                height={imageSize}
              />
            </Box>
            <Text textStyle="jmlFeatureSetTitle">{feature.title}</Text>
            <Text textStyle="jmlFeatureSetDescription">
              {feature.description}
            </Text>
          </VStack>
        ))}
      </SimpleGrid>
    </Container>
  )
}

export default FeatureSet
