import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import LogoJml from '@images/jml-logo.png'

const BrandOfJml: React.FC = () => {
  return (
    <Flex gap={3} alignItems="center">
      <Text textStyle="jmlText">A Brand by JML Singapore</Text>
      <Image src={LogoJml} alt="JML logo" width={64} height={36} />
    </Flex>
  )
}

export default BrandOfJml
