import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { Box, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import React from 'react'

const CategoryImage = ({
  id,
  image,
  text,
  handle,
  width,
  height
}: {
  id: string
  image: any
  text: string
  handle?: string
  width?: number
  height?: number
}) => {
  const { addCategory } = useFilterQueryParams()

  const imageWidth = width || 520
  const imageHeight = height || 420

  return (
    <Box
      id={id}
      position="relative"
      textAlign="center"
      width="100%"
      height="100%"
      cursor="pointer"
      onClick={() => {
        if (handle) {
          addCategory(handle)
        }
      }}
      data-aos="zoom-in"
    >
      <Image
        src={image}
        alt={text}
        width={imageWidth}
        height={imageHeight}
        quality={100}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        data-aos="zoom-in"
      ></Image>
      <Box
        bg={'blackOpacity'}
        position={'absolute'}
        top={'0'}
        left={'0'}
        height={'100%'}
        width={'100%'}
      ></Box>
      <Flex
        height={'100%'}
        width={'100%'}
        position={'absolute'}
        top={'0'}
        left={'0'}
        justifyContent={'center'}
        alignItems={'end'}
      >
        <Text textStyle="shopText" color={'white'} mb={3}>
          {text}
        </Text>
      </Flex>
    </Box>
  )
}

export default CategoryImage
